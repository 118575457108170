<template>
  <header
    class="fr-card-header items-center justify-between gap-3 p-2"
    :class="{
      'fr-card-header--transparent': transparent
    }"
  >
    <div
      class="flex w-full flex-row items-center justify-start gap-2 md:w-auto md:flex-auto"
    >
      <q-icon
        v-if="titleIcon"
        :name="titleIcon"
        class="ml-1 align-top text-lg md:text-2xl"
      />

      <div v-if="title" class="flex flex-col p-2">
        <h3
          class="text-lg font-light capitalize leading-none tracking-wide md:text-2xl md:font-medium"
        >
          {{ title }}
        </h3>
        <h4
          v-if="subtitle"
          class="text-base font-light leading-none opacity-80 md:mt-2.5 md:text-lg md:leading-5"
        >
          {{ subtitle }}
        </h4>
      </div>

      <div
        v-if="
          (showBackButton && $route.name !== 'home') ||
          showRefreshButton ||
          showSearch
        "
        class="flex w-full flex-row flex-nowrap gap-2 md:w-auto"
      >
        <fr-button
          v-if="showBackButton && $route.name !== 'home'"
          icon="fas fa-chevron-left"
          variant="ghost"
          @click="$router.go(-1)"
        >
          <q-tooltip>Go back</q-tooltip>
        </fr-button>

        <fr-button
          v-if="showRefreshButton"
          icon="fas fa-redo-alt"
          variant="ghost"
          :loading="loading"
          :disabled="loading"
          @click="() => $emit('refresh')"
        >
          <q-tooltip>Refresh</q-tooltip>
        </fr-button>

        <q-input
          v-if="showSearch"
          v-model="search"
          debounce="800"
          dense
          outlined
          :input-class="searchUppercase ? 'uppercase' : ''"
          class="w-full md:w-60 lg:w-80"
          :dark="$q.dark.isActive"
          clearable
          clear-icon="clear"
          hide-bottom-space
          :placeholder="searchPlaceholder"
          :label="searchLabel"
        />

        <slot name="search" />
      </div>
      <slot name="left" />
    </div>
    <div
      v-if="
        slots.right ||
        slots['country-selector'] ||
        slots['default-printers-button'] ||
        slots['regions-dropdown'] ||
        showFullscreenButton ||
        showServiceButton ||
        showCloseButton
      "
      class="flex w-full flex-row flex-nowrap items-center justify-end gap-2 md:w-auto md:flex-auto"
    >
      <slot name="right" />

      <regions-dropdown v-if="showRegionDropdown" />

      <country-selector v-if="showCountrySelector">
        <slot name="country-selector" />
      </country-selector>

      <fr-button
        v-if="
          !(useDeviceCheck() === 'mobile' || useDeviceCheck() === 'tablet') &&
          showFullscreenButton
        "
        variant="ghost"
        :icon="AppFullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
        @click="AppFullscreen.toggle()"
      >
        <q-tooltip anchor="top middle" self="bottom middle">
          {{ AppFullscreen.isActive ? 'Exit Fullscreen' : 'Go Fullscreen' }}
        </q-tooltip>
      </fr-button>

      <service-desk-modal
        v-if="showServiceButton"
        :issue-title="serviceDeskLabel"
        :issue-type="71"
        :child-issue-type="34"
      />

      <fr-button v-if="showCloseButton" v-close-popup size="xl" variant="ghost">
        <q-icon name="close" />
      </fr-button>
    </div>
  </header>
</template>

<script setup lang="ts">
import serviceDeskModal from '~/features/service-desk/components/service-desk-modal.vue'
import CountrySelector from '~/features/country/components/country-selector-widget.vue'
import RegionsDropdown from '~/components/widgets/regions-dropdown-widget.vue'
import useAuthStore from '~/features/auth/store'
import { AppFullscreen } from 'quasar'

withDefaults(
  defineProps<{
    titleIcon?: string | null
    title?: string | null
    subtitle?: string | null
    loading?: boolean
    searchLabel?: string
    searchPlaceholder?: string
    searchUppercase?: boolean
    serviceDeskLabel?: string
    showSearch?: boolean
    showRefreshButton?: boolean
    showServiceButton?: boolean
    showRegionDropdown?: boolean
    showCountrySelector?: boolean
    showBackButton?: boolean
    showCloseButton?: boolean
    showFullscreenButton?: boolean
    transparent?: boolean
  }>(),
  {
    titleIcon: null,
    title: null,
    subtitle: null,
    loading: false,
    searchLabel: 'Search...',
    searchPlaceholder: '',
    searchUppercase: false,
    serviceDeskLabel: 'Service Desk',
    showSearch: false,
    showRefreshButton: false,
    showServiceButton: false,
    showRegionDropdown: false,
    showCountrySelector: false,
    showBackButton: false,
    showCloseButton: false,
    showFullscreenButton: false,
    transparent: false
  }
)

const search = defineModel<string>('search', { default: '' })

const authStore = useAuthStore()

defineEmits<{
  (e: 'refresh'): void
}>()

const slots = computed(() => useSlots())

watch(
  () => AppFullscreen.isActive,
  (val: boolean) => {
    authStore.showDrawer = val ? false : true
    authStore.showHeaderToolbar = val ? false : true
  }
)
</script>

<style lang="sass">

.fr-card-header
  @apply p-2 items-center justify-between flex gap-2 flex-col w-full md:flex-row

/**
  * Neo
  */
.neo
  .fr-card-header
    @apply border-b border-fr-gray-500 bg-fr-gray-350 text-fr-gray-900

    &:first-of-type
      @apply bg-fr-gray-300

  &.dark
    .fr-card-header
      @apply border-fr-gray-200/40 bg-fr-gray-850 text-fr-gray-100

      &:first-of-type
        @apply bg-fr-gray-800

    .fr-card-header--transparent *
      @apply bg-transparent text-fr-gray-100

  .fr-card-header--transparent *
    @apply bg-transparent text-fr-gray-900

/**
  * Chowey
  */
.chowey
  .fr-card-header
    @apply bg-fr-primary-500 text-fr-gray-0 border-b border-fr-primary-500 rounded-t-md

    .q-field
      @apply bg-transparent

      .q-field__control
        @apply border-fr-gray-100/50

        *
          @apply text-fr-gray-100

    &:first-of-type
      @apply bg-fr-primary-500

  &.dark
    .fr-card-header
      @apply bg-fr-primary-600 text-fr-gray-0 border-b border-fr-primary-600

    .q-field
      @apply bg-transparent

      .q-field__control
        @apply border-fr-gray-100/50
        *
          @apply text-fr-gray-100

    .fr-card-header--transparent *
      @apply bg-transparent text-fr-gray-0

  .fr-card-header--transparent *
    @apply bg-transparent text-fr-gray-900
</style>
