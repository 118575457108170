<template>
  <fr-button
    class="items-cente w-12 overflow-hidden"
    @click.prevent="countryModal = true"
  >
    <img
      :src="countryIcon"
      class="absolute left-0 top-0 h-full w-full object-cover"
    />
    <q-tooltip anchor="top middle" self="bottom middle">
      {{
        country !== null
          ? `
          Your region is set to ${country}
        `
          : 'Select Region'
      }}
    </q-tooltip>

    <slot />
  </fr-button>
  <q-dialog v-model="countryModal" position="top">
    <fr-page class="m-3 !h-[100vh] !w-[100vw] p-3 xl:!w-[50vw]">
      <fr-card>
        <fr-card-header
          title="Select Region"
          show-close-button
          class="!flex-row"
        />

        <fr-card-body class="!flex-row !flex-wrap gap-3">
          <fr-button
            v-for="(row, index) in countries"
            :key="index"
            v-close-popup
            variant="outline"
            :icon="`img:/warehouse/${row.name}.svg`"
            size="sm"
            class="flex-1"
            :disabled="
              regions && regions?.length > 0
                ? regions?.find((region) => region.name === row.name) ===
                  undefined
                : false
            "
            @click.prevent="pickCountry(row)"
          >
            {{ row.label }}
            <q-badge v-if="row.name === country" color="primary" floating>
              <q-icon name="check" />
            </q-badge>

            <q-tooltip>
              {{
                regions?.find((region) => region.name === row.name)
                  ? 'Select Region'
                  : 'You do not have access to this region. Please contact IT Support'
              }}
            </q-tooltip>
          </fr-button>
        </fr-card-body>
      </fr-card>
    </fr-page>
  </q-dialog>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import type { countrytype } from '~/features/auth/store/state'

const emit = defineEmits(['countrySelected'])
const authStore = useAuthStore()

const countryModal = ref(false)
const country = computed(() => authStore.selectedCountry.name)

const countries = computed(() => authStore.countries)

const regions = computed(() => authStore.user?.regions)

const selectedCountry = computed({
  get: () => authStore.selectedCountry,
  set: (val) => (authStore.selectedCountry = val)
})

// Creates computed property for country icon
const countryIcon = computed(() => {
  const countryIcon = country.value !== null ? country.value : 'RSA'
  return '/warehouse/' + countryIcon + '_SQUARE.svg'
})

// Creates a method for country picker
function pickCountry(Country: countrytype) {
  if (selectedCountry.value.name === Country.name) return false

  selectedCountry.value = Country
  emit('countrySelected', Country)

  notifier({
    type: 'success',
    message: `Region set to ${Country.label}`
  })
}
</script>
