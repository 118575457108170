<template>
  <fr-button
    variant="ghost"
    icon="fal fa-bullhorn"
    @click="() => (show = true)"
  >
    <q-tooltip anchor="top middle" self="bottom middle">
      Report an issue
    </q-tooltip>
  </fr-button>

  <q-dialog
    v-model="show"
    persistent
    :maximized="useDeviceCheck() === 'mobile'"
    position="right"
  >
    <fr-page class="m-3 min-w-full md:m-6 md:min-w-[500px]">
      <fr-card class="!w-full md:w-[500px] lg:w-[500px]">
        <fr-card-header title="Report a Issue" show-close-button />

        <service-desk-report-form />
      </fr-card>
    </fr-page>
  </q-dialog>
</template>

<script setup lang="ts">
import { useServiceDeskStore } from '../store'
import serviceDeskReportForm from '../components/service-desk-report-form.vue'

const props = defineProps<{
  issueType: number
  childIssueType: number
  issueTitle: string
}>()

const show = ref(false)

const serviceDesk = useServiceDeskStore()

const subject = computed({
  get: () => serviceDesk.issueForm.subject,
  set: (v) => (serviceDesk.issueForm.subject = v)
})

const selectedIssueTypeForForm = computed({
  get: () => serviceDesk.issueForm.issueType,
  set: (v) => (serviceDesk.issueForm.issueType = v)
})

const selectedIssueChildTypeForForm = computed({
  get() {
    return serviceDesk.issueForm.childIssueType
  },
  set(value) {
    serviceDesk.issueForm.childIssueType = value
  }
})

watchEffect(() => {
  if (props.issueType) {
    selectedIssueTypeForForm.value = props.issueType
  }
  if (props.childIssueType) {
    selectedIssueChildTypeForForm.value = props.childIssueType
  }
  if (props.issueTitle) {
    subject.value = props.issueTitle
  }
})
</script>
./service-desk-report-form.vue
