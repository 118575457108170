<template>
  <q-form
    ref="issueFormRef"
    key="create-wrike-issue-form"
    :data-index="1"
    class="flex w-full flex-col gap-3 p-2.5 py-3"
    @submit.prevent="createWrikeTicket"
  >
    <fr-card-body>
      <q-input
        v-model="subject"
        :readonly="isCreatingWrikeIssue"
        label="Summary of the issue"
        hint="Required"
        dense
        required
        :rules="[(val) => (!!val && val.length > 0) || 'Summary is required']"
        outlined
      />

      <div class="relative">
        <q-input
          v-model="description"
          :readonly="isCreatingWrikeIssue"
          outlined
          class="flex-1 relative"
          placeholder="Description of the issue"
          type="textarea"
          hint="Required"
          dense
          :rules="[
            (val) => (!!val && val.length > 0) || 'Description is required'
          ]"
          required
        />
        <!-- AI button for rewriting the description -->
        <div>
          <q-btn
            class="icon-only absolute bottom-10 right-2"
            icon="fal fa-stars"
            :loading="isLoadingChatbotMessages"
            :disable="isCreatingWrikeIssue || description.length <= 24"
            @click="() => newMessage()"
          >
            <q-tooltip> Rewrite the description with AI </q-tooltip>
          </q-btn>
        </div>
      </div>

      <!-- Assignees -->
      <div class="flex flex-1 flex-col gap-3">
        <p>Assign this task to (Assignees)</p>
        <q-select
          v-model="assignees"
          :options="
            assigneesOptions.map((assignee) => ({
              ...assignee,
              label: `${assignee.firstName} ${assignee.lastName}`,
              value: assignee.wrike_id
            }))
          "
          :readonly="isCreatingWrikeIssue"
          :loading="isFetchingWrikeUsers"
          :disable="isCreatingWrikeIssue"
          dense
          hint="Optional"
          multiple
          map-options
          class="flex-1"
          outlined
          transition-hide="jump-up"
          transition-show="jump-up"
          use-chips
          use-input
          @filter="assigneesFilterFn"
        >
          <template
            #option="{
              itemProps,
              opt: { firstName, lastName, avatar_url, profile_role }
            }"
          >
            <q-item v-bind="itemProps" clickable>
              <q-item-section avatar>
                <q-avatar square>
                  <img :src="avatar_url" />
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ firstName }} {{ lastName }}</q-item-label>
                <q-item-label caption>{{ profile_role }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <!-- Followers -->
      <div class="flex flex-1 flex-col gap-3">
        <p>Loop someone in (Followers)</p>
        <q-select
          v-model="followers"
          :options="
            followersOptions.map((assignee) => ({
              ...assignee,
              label: `${assignee.firstName} ${assignee.lastName}`,
              value: assignee.wrike_id
            }))
          "
          :readonly="isCreatingWrikeIssue"
          :loading="isFetchingWrikeUsers"
          :disable="isCreatingWrikeIssue"
          dense
          hint="Optional"
          multiple
          class="flex-1"
          outlined
          transition-hide="jump-up"
          transition-show="jump-up"
          use-chips
          use-input
          @filter="followersFilterFn"
        >
          <template
            #option="{
              itemProps,
              opt: { firstName, lastName, avatar_url, profile_role }
            }"
          >
            <q-item v-bind="itemProps" clickable>
              <q-item-section avatar>
                <q-avatar square>
                  <img :src="avatar_url" />
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ firstName }} {{ lastName }}</q-item-label>
                <q-item-label caption>{{ profile_role }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <!-- File Uploads -->
      <div
        v-if="
          !!(useDeviceCheck() === 'mobile' || useDeviceCheck() === 'tablet')
        "
        class="flex flex-col"
      >
        <q-item tag="label">
          <q-item-section>
            <q-item-label>Toggle to take picture</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-toggle v-model="useACamera" color="blue" />
          </q-item-section>
        </q-item>
      </div>

      <div class="flex flex-col">
        <p class="mb-3">Upload file(s)</p>
        <div v-if="!(useDeviceCheck() === 'mobile' || useDeviceCheck() === 'tablet')" >
          <!-- <six-dropzone

            id="dropzone"
            v-model="fileUploads"
            @drop.prevent="drop"
            @update:model-value="modelValue"
            class="!h-[200px]"
            @change="selectedFile"
          /> -->


          <div :class="{ hidden: fileUploads.length > 0 }">
            <input
              id="fileInput" class="block w-full mb-5 text-sm border border-slate-400 rounded-md" aria-describedby="file_input" multiple type="file" @change="handleFileChange">
          </div>

          <div v-if="fileUploads.length > 0" class="grid grid-cols-4 gap-2.5 mt-2.5">
            <div v-for="file in fileUploads" :key="file.name" class="w-40 h-40 relative">
              <!-- if file is image -->
              <img
                v-if="isImage(file)"
                :src="getFileUrl(file)"
                class="object-cover w-full h-full border rounded-md border-slate-400"
                :alt="file.name"
              />

               <!-- Show PDF icon if file is a PDF -->
              <div v-if="isPdf(file)" class="w-full h-full border rounded-md border-slate-400 flex gap-5 flex-col justify-center items-center">
                <i class="fas fa-file-pdf text-red-500 text-4xl"></i>
                <span class="text-[10px]">{{ file.name }}</span>
              </div>

              <!-- Display file name if not an image or PDF -->
              <span v-if="!isImage(file) && !isPdf(file)" class="w-full h-full border rounded-md border-slate-400 flex gap-5 flex-col justify-center items-center">
                <i class="fas fa-file text-slate-500 text-4xl"></i>
                <span class="text-[10px]">{{ file.name }}</span>
              </span>

              <span
                class="absolute hover:cursor-pointer font-medium inline-flex justify-center items-center -top-2.5 -right-2.5 text-white bg-red-300 hover:bg-red-500 rounded-full p-3 w-4 h-4"
                @click="removeFile(file)"
                >x</span>
            </div>
            <label for="fileInput" class="hover:cursor-pointer hover:bg-slate-300 font-medium inline-flex justify-center text-6xl items-center text-white bg-slate-300/50 w-40 h-40">+</label>
          </div>
        </div>

        <div v-else>
          <!-- FIXME: Watch this user or environment needs to be used now. Camera doesn't work -->
          <input
            id="camera_picture"
            ref="mobile"
            accept="image/*;capture=camera"
            capture="user"
            class="mobile-input w-full rounded-md"
            type="file"
            style="display: none"
          />
          <label
            for="camera_picture"
            class="bg-secondary text-uppercase rounded-borders q-pa-sm block items-center text-center font-medium text-fr-gray-0"
          >
            <q-icon name="fal fa-camera" size="22px" class="q-mr-sm" />
            Take a Picture
          </label>
        </div>
      </div>
    </fr-card-body>

    <fr-card-footer class="!justify-end bg-transparent border-none">
      <q-btn
        :disable="isCreatingWrikeIssue"
        :loading="isCreatingWrikeIssue"
        type="submit"
        label="Submit"
      />
    </fr-card-footer>
  </q-form>
</template>



<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Notify, QForm } from 'quasar'

import type { qselect_filter_update_type } from '~/types/quasar-types'
import { useServiceDeskStore } from '../store'
import type { WrikeUserType } from '../store/state'
import factories from '~/factories'

const emit = defineEmits(['goBackButton'])
const $q = useQuasar()
const serviceDesk = useServiceDeskStore()

const vueDropzoneRef = ref()
const fileUploads = ref<File[]>([])
const mobile = ref()
const issueFormRef = ref<QForm>()
const assigneesOptions = ref<WrikeUserType[]>([])
const followersOptions = ref<WrikeUserType[]>([])

const useACamera = ref(false)

const subject = computed({
  get: () => serviceDesk.issueForm.subject,
  set: (v) => (serviceDesk.issueForm.subject = v)
})

const description = computed({
  get: () => serviceDesk.issueForm.description,
  set: (v) => (serviceDesk.issueForm.description = v)
})

const assignees = computed({
  get: () => serviceDesk.issueForm.assignees,
  set: (v) => {
    if (wrikeUsers.value)
      followersOptions.value = wrikeUsers.value.filter(
        (f) => v.findIndex((x) => x.wrike_id === f.wrike_id) <= -1
      )

    serviceDesk.issueForm.assignees = v
  }
})

const followers = computed({
  get: () => serviceDesk.issueForm.users,
  set: (v) => {
    if (wrikeUsers.value)
      assigneesOptions.value = wrikeUsers.value.filter(
        (f) => v.findIndex((x) => x.wrike_id === f.wrike_id) <= -1
      )
    serviceDesk.issueForm.users = v
  }
})

const selectedIssueTypeForForm = computed({
  get: () => serviceDesk.issueForm.issueType,
  set: (v) => (serviceDesk.issueForm.issueType = v)
})

const selectedIssueChildTypeForForm = computed({
  get: () => serviceDesk.issueForm.childIssueType,
  set: (value) => (serviceDesk.issueForm.childIssueType = value)
})

function assigneesFilterFn(val: string, update: qselect_filter_update_type) {
  setTimeout(() => {
    update(() => {
      const users = wrikeUsers.value?.filter(
        (f) => followers.value.findIndex((x) => x.wrike_id === f.wrike_id) <= -1
      )

      if (!users) return

      if (val === '') {
        assigneesOptions.value = users
      } else {
        const needle = val.toLowerCase()
        assigneesOptions.value = users.filter(
          (v) =>
            v.firstName.toLowerCase().indexOf(needle) > -1 ||
            v.lastName.toLowerCase().indexOf(needle) > -1
        )
      }
    })
  }, 200)
}

function followersFilterFn(val: string, update: qselect_filter_update_type) {
  setTimeout(() => {
    update(() => {
      if (!wrikeUsers.value) return
      const users = wrikeUsers.value.filter(
        (f) => assignees.value.findIndex((x) => x.wrike_id === f.wrike_id) <= -1
      )

      if (val === '') {
        followersOptions.value = users
      } else {
        const needle = val.toLowerCase()
        followersOptions.value = users.filter(
          (v) =>
            v.firstName.toLowerCase().indexOf(needle) > -1 ||
            v.lastName.toLowerCase().indexOf(needle) > -1
        )
      }
    })
  }, 200)
}

/**
 * Fetches Wrike users and sets assignees and followers options.
 * @returns {Object} An object containing the fetched Wrike users and a boolean indicating if the data is being fetched.
 */
const { data: wrikeUsers, isFetching: isFetchingWrikeUsers } =
  factories.serviceDesk.queries.useGetWrikeUsers({
    refetchOnWindowFocus: false
  })

watch(
  () => wrikeUsers.value,
  (value) => {
    if (value) {
      assigneesOptions.value = value
      followersOptions.value = value
    }
  }
)

const { mutate: sendMessage, isPending: isLoadingChatbotMessages } =
  factories.AI.mutations.useSendMessage({
    onSuccess: (res) => {
      if (res && res.messages && res.messages.length > 0) {
        description.value = JSON.parse(
          res.messages[res.messages.length - 1].content
        ).message
      }
    }
  })

const i18nLocale = useI18n()

const newMessage = () => {
  sendMessage([
    {
      role: 'system',
      content: `Rewrite the description to make it more clear for the assignees and followers to understand the issue better.

        RULES:
        - Return the description in a clear and simple english manner.
        - Return it as JSON with the key "message" and the value as the new description.
        - Do not include any personal information in the description.
        - Do not introduce yourself in the description.
        - Try to break it down into simple sentences.

        SUMMARY: ${subject.value}
        DESCRIPTION: ${description.value}
        `,
      personality: {
        bot: {
          name: 'WrikeBot',
          language: i18nLocale.locale.value,
          tone: 'friendly, professional'
        },
        useFunctions: false
      }
    }
  ])
}

// const autoTypeNextLine = () => {
//   sendMessage([
//     {
//       role: 'system',
//       content: `Try to guess the next line the user will type

//         RULES:
//         - Return the next line the user will type.
//         - Return it as JSON with the key "message" and the value as the next line the user will type.
//         - Do not include any personal information in the description.
//         - Do not introduce yourself in the description.

//         PREVIOUS LINES: ${description.value}
//         `,
//       personality: {
//         bot: {
//           name: 'WrikeBot',
//           language: i18nLocale.locale.value,
//           tone: 'friendly, professional'
//         },
//         useFunctions: false
//       }
//     }
//   ])
// }

/**
 * Creates a Wrike issue and resets the issue form upon success.
 * @returns {Object} An object containing the createWrikeIssue function and a boolean indicating if the request is loading.
 */
const { mutate: createWrikeIssue, isPending: isCreatingWrikeIssue } =
  factories.serviceDesk.mutations.useCreateWrikeTicket({
    onSuccess: (response) => {
      vueDropzoneRef.value = null
      serviceDesk.issueForm = {
        ...serviceDesk.issueForm,
        assignees: [],
        users: [],
        description: '',
        subject: '',
        files: []
      }
      emit('goBackButton')
      Notify.create({
        multiLine: true,
        timeout: 20000,
        progress: true,
        message: `<div class="font-medium">Ticket successfully created!</div><div>${response.data[0].title}</div>`,
        html: true,
        color: 'green',
        icon: 'done',
        actions: [
          {
            label: 'Dismiss',
            color: 'white',
            handler: () => {
              /* ... */
            }
          },
          {
            label: 'Open on Wrike',
            color: 'white',
            handler: () => window.open(response.wrikeLink, '_blank')
          }
        ]
      })
    }
  })

// watch(
//   () => fileUploads.value,
//   () => {
//     console.log('fileUploads.value ==')
//     console.log(fileUploads.value)
//   }
// )

const removeFile = (fileToRemove: File) => {
  fileUploads.value = fileUploads.value.filter(file => file !== fileToRemove)
}

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement | null;

    if (target && target.files) {
      const selectedFiles = Array.from(target.files);
      fileUploads.value = [...fileUploads.value, ...selectedFiles];
    }
}

const isImage = (file: File) => file.type.startsWith('image/');

// Determine if the file is a PDF
const isPdf = (file: File) => file.type === 'application/pdf';

// Generate a URL for the file
const getFileUrl = (file: File) => URL.createObjectURL(file);

async function createWrikeTicket() {
  /**
   * Get the dropzone instance
   */
  //const dropzone = [vueDropzoneRef.value]

  // get files
  const uploadedFiles: File[] = fileUploads.value as File[];

  /**
   * Collect all files
   */
  // const files: File[] = useACamera.value
  //   ? Array.from(mobile.value.files || [])
  //   : dropzone || []
  const files: File[] = useACamera.value ? Array.from(mobile.value.files || []) : uploadedFiles || []

  /**
   * Validate the form
   * On success, create the confirmation dialog and proceed
   */
  issueFormRef.value?.validate().then((success) => {
    if (
      success &&
      selectedIssueTypeForForm.value &&
      selectedIssueChildTypeForForm.value
    ) {
      const issueType = selectedIssueTypeForForm.value
      const childIssueType = selectedIssueChildTypeForForm.value

      // yay, models are correct
      $q.dialog({
        message: 'Press OK to confirm creating the Wrike task',
        persistent: true,
        cancel: true
      }).onOk(() => {
        const data = {
          description: description.value,
          summary: subject.value,
          issueType,
          childIssueType,
          assignees: serviceDesk.issueForm.assignees.map(
            ({ wrike_id: wrikeId }: { wrike_id: string }) => wrikeId
          ),
          users: serviceDesk.issueForm.users.map(
            ({ wrike_id: wrikeId }: { wrike_id: string }) => wrikeId
          ),
          files
        }

        const formData = new FormData()

        formData.append('description', data.description)
        formData.append('summary', data.summary)
        formData.append('issueType', issueType.toString())
        formData.append('childIssueType', childIssueType.toString())

        data.assignees.forEach((assignee, index) => {
          formData.append(`assignees[${index}]`, assignee)
        })

        data.users.forEach((user, index) => {
          formData.append(`users[${index}]`, user)
        })

        files.forEach((file, index) => {
          formData.append(`files[${index}]`, file)
        })
        formData.forEach((value, key) => {
          console.log(`${key}:`, value)
        })

        return createWrikeIssue(formData)
      })
    } else {
      // oh no, user has outlined in
      // at least one invalid value
    }
  })

  return
}
</script>


<style>
  input[type="file"]::file-selector-button {
    width: 136px;
    color: white;
    background-color: #a3a3a3;
    border: none;
    padding: 5px 0;
  }

  .q-field__bottom .q-field__messages{
    margin-top: -3px;
    opacity: 0.4;
    text-align: right;
  }
</style>
